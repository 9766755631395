var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c('h1',[_c('span',{staticClass:"breadcrumbs"},[_c('router-link',{staticClass:"link",attrs:{"to":"/"}},[_vm._v("Systems")]),_c('span',{staticClass:"sep"},[_vm._v("→")]),_c('router-link',{staticClass:"link nobreak",attrs:{"to":'/' + _vm.countryCode}},[_c('span',{staticClass:"flag"},[_vm._v(_vm._s(_vm.flag(_vm.countryCode))+" ")]),_c('span',{staticClass:"countryCode"},[_vm._v(_vm._s(_vm.countryCode.toUpperCase()))])]),_c('span',{staticClass:"sep"},[_vm._v("→")]),_c('span',{staticClass:"systemID"},[(!_vm.systemsLoaded)?_c('span',{staticClass:"loading"},[_vm._v(_vm._s(_vm.systemID))]):_c('span',[_vm._v(_vm._s(_vm.system.name))])])],1)]),_c('SubMenu',{attrs:{"countryCode":_vm.countryCode,"systemID":_vm.systemID}}),_c('div',{staticClass:"workspace"},[_c('Station',{staticClass:"station"}),_c('Mapbox',{attrs:{"access-token":_vm.accessToken,"map-options":{
        style: _vm.style,
        fitBoundsOptions: {
          padding: { top: 50, bottom: 50, left: 50, right: 50 }
        }
      },"geolocate-control":{
        show: true,
        position: 'top-right'
      }},on:{"map-load":_vm.loaded,"map-init":_vm.initialized}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }