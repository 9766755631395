<template>
  <div id="app">
    <ul id="nav">
      <li>
        <span id="logo">
          Bike Share
          <div class="icon flip">🚲</div>
        </span>
      </li>
      <li>
        <router-link to="/"><span>Systems</span></router-link>
      </li>
      <li>
        <router-link to="/about"><span>About</span></router-link>
      </li>
    </ul>
    <router-view />
  </div>
</template>

<script>
export default {
  watch: {
    $route: {
      handler(to) {
        // Station has a Map on it, that has to take all the height
        if (to.name == "Stations") {
          document.body.classList.add("stations");
        } else {
          document.body.classList.remove("stations");
        }
      },
      immediate: true
    }
  }
};
</script>

<style>
:root {
  color-scheme: light dark; /* both supported */
}

#app {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* color: #2c3e50; */
  font-size: 10pt;
}

body {
  padding: 0;
  margin: 0;
  background: #fff;
  color: #1d1d1f;
  font-size: 17px;
  line-height: 1.47059;
  font-weight: 400;
  letter-spacing: -0.022em;
}

.stations #app {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100vh;
  overflow: hidden;
}

a {
  color: #0070c9;
  text-decoration: none;
}

.link {
  text-decoration: none;
  border-bottom: 1px solid rgba(0, 112, 201, 0.4);
}

a:visited {
  color: #7000b6;
}

a.link:visited,
a:visited .link {
  border-bottom: 1px solid rgba(112, 0, 182, 0.4);
}

#nav {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  background: rgba(0, 0, 0, 0.1);
  user-select: none;
}

#nav a {
  display: inline-block;
  font-size: 14px;
  line-height: 3.14286;
  font-weight: 400;
  letter-spacing: -0.01em;
  padding: 0 10px;
  height: 44px;
  opacity: 0.8;
  color: #000;
  text-decoration: none;
}

#nav li + li {
  margin-left: 1rem;
}

#logo {
  padding: 0 1rem;
  font-size: 18px;
  font-weight: bold;
  vertical-align: text-top;
}

#nav .icon {
  font-size: 24px;
  margin-left: 6px;
  display: inline-block;
}

.page {
  padding: 0;
  margin: 0;
}

.content {
  padding: 0.33rem 1rem 1rem;
}

.flip {
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.nobreak {
  white-space: nowrap;
}

h1 {
  padding: 1rem;
  margin: 0;
  font-size: 1.5rem;
  color: #121212;
  background: rgba(0, 0, 0, 0.05);
}

h1 .breadcrumbs .sep {
  margin: 0 0.5rem;
  font-weight: 400;
}

h1 .link {
  border-bottom-width: 2px;
}

.table {
  border-collapse: collapse;
  border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid #c5c5c5;
  border-left: 1px solid #c6c6c6;
  border-right: 1px solid #c6c6c6;
}

.table th {
  font-weight: 500;
  color: #474646;
  border-bottom: 1px solid #e2e2e2;
}

.table th,
.table td {
  padding: 0.33rem 1rem;
  text-align: left;
  vertical-align: top;
}

.table th + th {
  border-left: 1px solid #e5e5e5;
}

.table tr:nth-child(2n) {
  background: #f5f5f5;
}

@media (prefers-color-scheme: dark) {
  body {
    background: #2a2a2a;
    color: #d8d8d8;
  }
  a {
    color: #81b1f9;
  }
  .link {
    border-bottom: 1px solid rgba(129, 177, 249, 0.4);
  }
  a:visited {
    color: #bf81e5;
  }
  a.link:visited,
  a:visited .link {
    border-bottom: 1px solid rgba(191, 129, 229, 0.4);
  }
  #nav {
    background: #515151;
    color: #cfcfcf;
  }
  #nav a {
    color: #cfcfcf;
    border: none;
  }
  #nav a span {
    border-bottom: 1px solid rgba(207, 207, 207, 0.4);
  }
  #nav .icon {
    filter: invert(1);
  }
  h1 {
    color: #cccccc;
    background: #3a3a3a;
  }
  .table {
    border-top: 1px solid #4d4d4d;
    border-bottom: 1px solid #4d4d4d;
    border-left: 1px solid #3a3a3a;
    border-right: 1px solid #3a3a3a;
  }
  .table th {
    background: #333333;
    color: #ffffff;
    border-bottom: 1px solid #4b4b4b;
  }
  .table th + th {
    border-left: 1px solid #484848;
  }
  .table tr {
    background: #3d3d3d;
  }
  .table tr:nth-child(2n) {
    background: #464644;
  }
}
</style>
