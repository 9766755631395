import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import { GET_SYSTEMS_QUERY } from "../graphql/queries/getSystems";
import { GET_STATION_STATUS_QUERY } from "../graphql/queries/getStationStatus";
import { apolloClient } from "../graphql/apollo";

Vue.use(Vuex);

interface Feed {
  name: string;
  url: string;
  language: string;
}

interface System {
  id: string;
  name: string;
  location: string;
  countryCode: string;
  autoDiscoveryUrl: string;
  url: string;
  feeds: [Feed];
}

interface StationStatus {
  id: string;
  numBikesAvailable: number;
  numBikesDisabled: number;
  numDocksAvailable: number;
  isInstalled: boolean;
  isRenting: boolean;
  isReturning: boolean;
  lastReported: string;
}

interface Country {
  counter: number;
  countryCode: string;
  systems: string[];
}

const store = new Vuex.Store({
  state: {
    systemsLoaded: false, // once systems loaded this value will be switched to "true" and never change till page reload
    systems: [], // array of all GBFS systems
    countries: [], // array of Country, sorted by counter
    stations: [], // array of Stations
    station: {}, // selected Station
    stationStatus: {} // map stationID → StationStatus
  },
  mutations: {
    setSystems(state, payload) {
      state.systems = payload;
      state.systemsLoaded = true;
    },
    setCountries(state, payload) {
      state.countries = payload;
    },
    setStations(state, payload) {
      state.stations = payload;
      state.station = {}; // deselect station if stations loaded for other system
    },
    setStation(state, payload) {
      state.station = payload;
    },
    setStationStatus(state, payload) {
      state.stationStatus = payload;
    }
  },
  actions: {
    // component that needs countries will trigger getSystems()
    async getSystems(context) {
      if (this.state.systemsLoaded) {
        // do not reload systems automatically
        return;
      }

      console.log("POST getSystems");
      const responseRaw = await apolloClient.query({
        query: GET_SYSTEMS_QUERY
      });
      // todo: potential "undefined" error ⬇️
      const systems = responseRaw.data.systems.edges.map(
        (edge: Record<string, System>) => edge.node as System
      );

      context.commit("setSystems", systems);

      const countriesMap: { [countryCode: string]: Country } = systems.reduce(
        (r: { [countryCode: string]: Country }, v: System) => {
          if (r[v.countryCode] == undefined) {
            r[v.countryCode] = {
              countryCode: v.countryCode,
              counter: 0,
              systems: []
            };
          }
          r[v.countryCode].counter++;
          r[v.countryCode].systems.push(v.name);
          return r;
        },
        {}
      );

      const countriesSorted = Object.entries(countriesMap).sort(
        ([, a], [, b]) => b.counter - a.counter
      );
      context.commit("setCountries", countriesSorted);
    },
    async getStations(context, data) {
      context.commit("setStations", []);
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL + "/geojson?systemID=" + data.payload
        )
        .then(response => {
          context.commit("setStations", response.data);
        });
    },
    async selectStation(context, data) {
      context.commit("setStation", data.payload);
    },
    async deselectStation(context) {
      context.commit("setStation", {});
    },
    async getStationStatus(context, data) {
      console.log("POST getStationStatus");
      const responseRaw = await apolloClient.query({
        query: GET_STATION_STATUS_QUERY,
        variables: {
          systemID: data.payload
        }
      });
      // todo: potential "undefined" error ⬇️
      const stationStatus = responseRaw.data.stationStatus.edges
        .map(
          (edge: Record<string, StationStatus>) => edge.node as StationStatus
        )
        .reduce(function(map: any, obj: StationStatus) {
          map[obj.id] = obj;
          return map;
        }, {});

      context.commit("setStationStatus", stationStatus);
    }
  }
});
export default store;
