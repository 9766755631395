import gql from "graphql-tag";

export const GET_SYSTEMS_QUERY = gql`
  query getSystems {
    systems {
      edges {
        node {
          id
          name
          url
          location
          countryCode
          autoDiscoveryUrl
          feeds {
            name
            url
            language
          }
        }
      }
    }
  }
`;
