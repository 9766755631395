<template>
  <div class="page">
    <h1>
      <span class="breadcrumbs">
        <router-link to="/" class="link">Systems</router-link>
        <span class="sep">→</span>
      </span>
      <span class="flag">{{ flag(countryCode) }} </span>
      <span class="countryCode">{{ countryCode.toUpperCase() }}</span>
    </h1>
    <div v-if="!systemsLoaded" class="content">Loading...</div>
    <div v-else>
      <table class="table">
        <thead>
          <tr>
            <th>Name</th>
            <th class="hide-on-phone">Location</th>
            <th>Website</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="system in systems.filter(
              s => s.countryCode.toLowerCase() == countryCode
            )"
            :key="system.id"
          >
            <td>
              <router-link
                :to="'/' + countryCode + '/' + system.id + '/stations'"
                class="link"
              >
                {{ system.name }}
              </router-link>
            </td>
            <td class="hide-on-phone">{{ system.location }}</td>
            <td>
              <a :href="system.url" class="link">{{ system.url }}</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import { mapState } from "vuex";

import store from "../store/store";
import * as type from "../store/types";

export default {
  name: "Systems",
  data() {
    return {
      countryCode: ""
    };
  },
  async mounted() {
    this.countryCode = this.$route.params.countryCode;
    console.log("<Systems countryCode=" + this.countryCode + ">");

    this.getSystems();
  },
  methods: {
    getSystems() {
      store.dispatch({ type: type.GetSystems });
    },
    flag(countryCode) {
      if (!countryCode) {
        return "";
      }
      return getUnicodeFlagIcon(countryCode);
    }
  },
  computed: mapState({
    systemsLoaded: state => state.systemsLoaded,
    systems: state => state.systems
  })
};
</script>

<style scoped>
.page table {
  width: 100%;
}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  .hide-on-phone {
    display: none;
  }
}
</style>
