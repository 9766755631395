<template>
  <div class="page">
    <h1>
      <span class="breadcrumbs">
        <router-link to="/" class="link">Systems</router-link>
        <span class="sep">→</span>
        <router-link :to="'/' + countryCode" class="link nobreak">
          <span class="flag">{{ flag(countryCode) }} </span>
          <span class="countryCode">{{ countryCode.toUpperCase() }}</span>
        </router-link>
        <span class="sep">→</span>
        <span class="systemID">
          <span v-if="!systemsLoaded" class="loading">{{ systemID }}</span>
          <span v-else>{{ system.name }}</span>
        </span>
      </span>
    </h1>
    <SubMenu :countryCode="countryCode" :systemID="systemID" />
    <div class="workspace">
      <Station class="station" />
      <Mapbox
        :access-token="accessToken"
        :map-options="{
          style: style,
          fitBoundsOptions: {
            padding: { top: 50, bottom: 50, left: 50, right: 50 }
          }
        }"
        :geolocate-control="{
          show: true,
          position: 'top-right'
        }"
        @map-load="loaded"
        @map-init="initialized"
      />
    </div>
  </div>
</template>

<script>
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import { mapState } from "vuex";
import Mapbox from "mapbox-gl-vue";

import store from "../store/store";
import * as type from "../store/types";
import SubMenu from "../components/SubMenu.vue";
import Station from "../components/Station.vue";

export default {
  name: "System",
  components: {
    SubMenu,
    Station,
    Mapbox
  },
  data() {
    return {
      map: {},
      countryCode: "",
      systemID: "",
      systemName: "...",
      boundsSet: false,
      sourceAdded: false,
      accessToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN
    };
  },
  async mounted() {
    this.countryCode = this.$route.params.countryCode;
    this.systemID = this.$route.params.systemID;
    console.log(
      "<Stations countryCode=" +
        this.countryCode +
        " systemID=" +
        this.systemID +
        ">"
    );

    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", event => {
        if (event.matches) {
          console.log("It's dark now 🌑");
          this.map.setStyle(this.getMapStyleByColorScheme("dark"));
        } else {
          console.log("It's light now 🌕");
          this.map.setStyle(this.getMapStyleByColorScheme("light"));
        }
      });

    this.getSystems();
    this.getStations(this.systemID);
  },
  methods: {
    getSystems() {
      store.dispatch({ type: type.GetSystems });
    },
    getStations(systemID) {
      store.dispatch({ type: type.GetStations, payload: systemID });
    },
    selectStation(payload) {
      store.dispatch({ type: type.SelectStation, payload: payload });
      store.dispatch({ type: type.GetStationStatus, payload: this.systemID });
    },
    initialized(map) {
      this.map = map;
      map.on("style.load", this.styleLoaded);
    },
    loaded() {
      console.log("Map loaded");
    },
    styleLoaded() {
      console.log("Style loaded");
      // Called on first render and every time color scheme changes.
      // Needed to re-add layer on map style change (light <-> dark).
      this.addLayer(this.map);
    },
    addLayer(map) {
      if (!this.styleLoaded) {
        console.log("⏱ Tried to add layer to map when style not loaded");
        return;
      }

      if (map == undefined) {
        console.log("🐛 Tried to add layer to 'undefined' map");
        return;
      }

      if (this.stations.length == 0) {
        console.log("🐛 Tried to add layer without stations");
        return;
      }

      if (!this.boundsSet) {
        this.boundsSet = true;
        map.fitBounds(this.bounds(this.stations), {
          padding: 100,
          duration: 0
        });
      }

      if (!this.sourceAdded) {
        map.addSource("stations", {
          type: "geojson",
          data: this.stations
        });

        map.addLayer({
          id: "stations",
          type: "symbol",
          source: "stations",
          layout: {
            "icon-image": "bicycle-11",
            "icon-anchor": "bottom",
            "icon-offset": [0, 5],
            "icon-allow-overlap": true
          }
        });

        this.sourceAdded = true;
      } else {
        map.getSource("stations").setData(this.stations);
      }

      map.on("click", "stations", e => {
        this.selectStation(e.features[0].properties);
      });

      // Change the cursor to a pointer when the mouse is over the places layer.
      map.on("mouseenter", "stations", function() {
        map.getCanvas().style.cursor = "pointer";
      });

      // Change it back to a pointer when it leaves.
      map.on("mouseleave", "stations", function() {
        map.getCanvas().style.cursor = "";
      });
    },
    getMapStyleByColorScheme(scheme) {
      if (scheme == "dark") {
        return "mapbox://styles/mapbox/dark-v10";
      }
      return "mapbox://styles/mapbox/light-v10";
    },
    flag(countryCode) {
      if (!countryCode) {
        return "";
      }
      return getUnicodeFlagIcon(countryCode);
    },
    bounds(stations) {
      let minLon = 180;
      let minLat = 180;
      let maxLon = -180;
      let maxLat = -180;

      stations.features.forEach(feature => {
        const c = feature.geometry.coordinates;

        minLon = Math.min(minLon, c[0]);
        minLat = Math.min(minLat, c[1]);
        maxLon = Math.max(maxLon, c[0]);
        maxLat = Math.max(maxLat, c[1]);
      });

      return [
        [minLon, minLat],
        [maxLon, maxLat]
      ];
    }
  },
  computed: {
    style() {
      if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
      ) {
        console.log("It's dark 🌑");
        return this.getMapStyleByColorScheme("dark");
      }

      console.log("It's light 🌕");
      return this.getMapStyleByColorScheme("light");
    },
    system() {
      const systems = this.systems.filter(system => system.id == this.systemID);
      return systems[0] || {};
    },
    ...mapState({
      systemsLoaded: state => state.systemsLoaded,
      systems: state => state.systems,
      stations: state => state.stations,
      station: state => state.station
    })
  },
  watch: {
    stations: function() {
      console.log("Stations loaded");
      if (this.map != {}) {
        this.addLayer(this.map);
      }
    },
    systemID: function() {
      // When System changes need to reset map bounds.
      console.log("Reset map bounds");
      this.boundsSet = false;
      // And resize map
      console.log("Resizing map");
      if (this.map != {}) {
        this.map.resize();
      }
    },
    station: function(val) {
      // When station deselected, resize the map
      // (because sometimes MapBox stops resizing the map after closing Station panel)
      if (val.id == undefined && this.map != {}) {
        // immediate resize sometimes too fast and happens before Vue histes Station panel
        console.log("Resizing map after 100ms");
        setTimeout(function() {this.map.resize()}.bind(this), 100);
      }
    }
  }
};
</script>

<style scoped>
.page {
  flex-grow: 4;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.workspace {
  flex-grow: 4;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}
.station {
  width: 300px;
  padding: 1rem;
  border-right: 1px solid #dddddd;
}
#map {
  flex-grow: 4;
}
@media (prefers-color-scheme: dark) {
  .station {
    border-color: #4d4d4d;
  }
}
</style>
