import Vue from "vue";
import Vuex from "vuex";
import App from "./App.vue";
import { apolloProvider } from "./graphql/apollo";
import router from "./router";
import store from "./store/store";

console.log(
  "🐛 See a bug? Help the project! Report an issue or submit a pull-request: https://github.com/chuhlomin/gbfs-console/issues/new"
);

Vue.config.productionTip = false;
Vue.use(Vuex);

new Vue({
  apolloProvider,
  router,
  store,
  render: h => h(App)
}).$mount("#app");
