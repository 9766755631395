<template>
  <div class="page">
    <h1>Systems</h1>
    <div v-if="!systemsLoaded" class="content">Loading...</div>
    <div v-else>
      <ul class="countriesList">
        <li
          class="countryLine"
          v-for="country in countries"
          :key="country[1].countryCode"
        >
          <router-link
            :to="'/' + country[1].countryCode.toLowerCase()"
            class="country"
          >
            <span class="flag" :title="country[1].countryCode">
              {{ getUnicodeFlagIcon(country[1].countryCode) }}
            </span>
            <span class="countryCode" :title="country[1].country">
              {{ country[1].countryCode }}
            </span>
            <span class="counter">{{ country[1].counter }}</span>
            <span class="systems">{{ country[1].systems.join(", ") }}</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import { mapState } from "vuex";

import store from "../store/store";
import * as type from "../store/types";

export default {
  name: "Countries",
  data() {
    return {
      getUnicodeFlagIcon: getUnicodeFlagIcon
    };
  },
  async mounted() {
    console.log("<Countries>");
    this.getSystems();
  },
  methods: {
    getSystems() {
      store.dispatch({ type: type.GetSystems });
    }
  },
  computed: mapState({
    systemsLoaded: state => state.systemsLoaded,
    countries: state => state.countries
  })
};
</script>

<style scoped>
.countriesList {
  columns: 3 300px;
  column-fill: balance;
  column-gap: 1px;
  list-style: none;
  padding: 0;
  margin: 0;
  border-top: 1px solid #dddddd;
}

.countryLine {
  background: #ffffff;
  page-break-inside: avoid;
  break-inside: avoid;
  -webkit-column-break-inside: avoid;
}

.countryLine:nth-child(2n) {
  background: #f4f5f5;
}

.country {
  display: block;
  padding: 0.33rem 1rem;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.country:hover {
  background: rgba(0, 0, 0, 0.1);
}

.countryCode {
  display: inline-block;
  width: 1.3rem;
  margin-left: 0.33rem;
  font-weight: bold;
  text-transform: uppercase;
}

.counter {
  margin-left: 0.5rem;
  padding: 0 0.33rem;
}

.systems {
  margin-left: 0.5rem;
}
@media (prefers-color-scheme: dark) {
  .countriesList {
    border-color: #4d4d4d;
  }
  .countryLine {
    background: #3d3d3d;
  }
  .countryLine:nth-child(2n) {
    background: #464644;
  }
  .country:hover {
    background: rgba(0, 0, 0, 0.2);
  }
}
</style>
