<template>
  <div class="page">
    <h1>
      <span class="breadcrumbs">
        <router-link to="/" class="link">Systems</router-link>
        <span class="sep">→</span>
        <router-link :to="'/' + countryCode" class="link nobreak">
          <span class="flag">{{ flag(countryCode) }} </span>
          <span class="countryCode">{{ countryCode.toUpperCase() }}</span>
        </router-link>
        <span class="sep">→</span>
        <span class="systemID">
          <span v-if="!systemsLoaded" class="loading">{{ systemID }}</span>
          <span v-else>{{ system.name }}</span>
        </span>
      </span>
    </h1>
    <div v-if="!systemsLoaded" class="content">Loading...</div>
    <div v-else>
      <SubMenu :countryCode="countryCode" :systemID="systemID" />
      <div class="content">
        <dl>
          <dt>ID</dt>
          <dd>{{ system.id }}</dd>
          <dt>Name</dt>
          <dd>{{ system.name }}</dd>
          <dt>Location</dt>
          <dd>{{ system.location }}</dd>
          <dt>URL</dt>
          <dd>
            <a :href="system.url" class="link">
              {{ system.url }}
            </a>
          </dd>
          <dt>Auto-discovery URL</dt>
          <dd>
            <a :href="system.autoDiscoveryUrl" class="link">
              {{ system.autoDiscoveryUrl }}
            </a>
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import { mapState } from "vuex";
import store from "../store/store";
import * as type from "../store/types";
import SubMenu from "../components/SubMenu.vue";

export default {
  name: "System",
  components: {
    SubMenu
  },
  data() {
    return {
      countryCode: "",
      systemID: "",
      systemName: "..."
    };
  },
  async mounted() {
    this.countryCode = this.$route.params.countryCode;
    this.systemID = this.$route.params.systemID;
    console.log(
      "<System countryCode=" +
        this.countryCode +
        " systemID=" +
        this.systemID +
        ">"
    );

    this.getSystems();
  },
  methods: {
    getSystems() {
      store.dispatch({ type: type.GetSystems });
    },
    flag(countryCode) {
      if (!countryCode) {
        return "";
      }
      return getUnicodeFlagIcon(countryCode);
    }
  },
  computed: {
    system() {
      const systems = this.systems.filter(system => system.id == this.systemID);
      return systems[0] || {};
    },
    ...mapState({
      systemsLoaded: state => state.systemsLoaded,
      systems: state => state.systems
    })
  }
};
</script>

<style scoped>
.loading {
  font-style: italic;
  opacity: 0.35;
}
.content {
  padding: 1rem;
}
dl {
  display: grid;
  grid-template-columns: max-content auto;
  margin: 0;
  border-radius: 4px;
  overflow: hidden;
}
dt {
  grid-column-start: 1;
  font-weight: 600;
  background: rgba(255, 255, 255, 0.05);
  padding: 0.3rem 1rem;
}

dd {
  grid-column-start: 2;
  margin: 0;
  padding: 0.3rem 1rem;
  background: rgba(255, 255, 255, 0.025);
}
</style>
