<template>
  <div v-if="station.name" class="panel">
    <h2>{{ station.name }}</h2>
    <a class="close" v-on:click="closePanel"><span class="inner">×</span></a>
    ID: {{ station.id }}<br />
    Address: {{ station.address || "?" }}<br /><br />
    Bikes available: {{ stationStatus.numBikesAvailable || "?" }}<br />
    Docks available: {{ stationStatus.numDocksAvailable || "?" }}<br />

    <div v-if="this.getFeedByName('station_status')">
      <hr />
      <a :href="this.getFeedByName('station_status').url" target="_blank">Source</a>
    </div>
  </div>
</template>

<script>
import store from "../store/store";
import * as type from "../store/types";
import { mapState } from "vuex";

export default {
  name: "Station",
  computed: {
    ...mapState({
      station: state => state.station,
      allStationStatus: state => state.stationStatus,
      systems: state => state.systems
    }),
    stationStatus() {
      if (this.station == {}) {
        return {};
      }
      return this.allStationStatus[this.station.id] || {};
    },
    system() {
      const systems = this.systems.filter(system => system.id == this.$route.params.systemID);
      return systems[0] || {};
    }
  },
  methods: {
    closePanel() {
      store.dispatch({ type: type.DeselectStation });
    },
    getFeedByName(name) {
      return this.system.feeds.filter(feed => feed.name == name)[0];
    }
  }
};
</script>

<style scoped>
h2 {
  margin: 0;
  padding: 0 2rem 1rem 0;
}
.panel {
  position: relative;
}
.close {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  display: block;
  padding: 0.5rem;
  font-size: 15pt;
  font-weight: 500;
  text-align: center;
  line-height: 15pt;
  color: #aaaaaa;
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}
.close .inner {
  display: block;
  width: 16pt;
  height: 16pt;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 100%;
}
.close:hover .inner {
  background: rgba(0, 0, 0, 0.15);
}
hr {
  border: 0;
  height: 1px;
  background-color: #4d4d4d;
}
@media (prefers-color-scheme: dark) {
  .close .inner {
    background: rgba(255, 255, 255, 0.2);
  }
  .close:hover .inner {
    background: rgba(255, 255, 255, 0.3);
  }
}
</style>
