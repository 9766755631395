import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Countries from "../views/Countries.vue";
import Systems from "../views/Systems.vue";
import System from "../views/System.vue";
import Stations from "../views/Stations.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/",
    name: "Countries",
    component: Countries
  },
  {
    path: "/:countryCode",
    name: "Systems",
    component: Systems,
    props: route => ({ countryCode: route.query.countryCode })
  },
  {
    path: "/:countryCode/:systemID",
    name: "System",
    component: System,
    props: route => ({
      countryCode: route.query.countryCode,
      systemID: route.query.systemID
    })
  },
  {
    path: "/:countryCode/:systemID/stations",
    name: "Stations",
    component: Stations,
    props: route => ({
      countryCode: route.query.countryCode,
      systemID: route.query.systemID
    })
  },
  {
    path: "*",
    name: "404",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/404.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
