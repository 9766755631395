import gql from "graphql-tag";

export const GET_STATION_STATUS_QUERY = gql`
  query getStationStatus($systemID: String) {
    stationStatus(systemID: $systemID) {
      edges {
        node {
          id
          numBikesAvailable
          numBikesDisabled
          numDocksAvailable
          isInstalled
          isRenting
          isReturning
          lastReported
        }
      }
    }
  }
`;
