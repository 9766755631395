<template>
  <ul class="submenu">
    <li>
      <router-link :to="'/' + countryCode + '/' + systemID + '/stations'">
        <span class="link">Stations</span>
      </router-link>
    </li>
    <li>
      <router-link :to="'/' + countryCode + '/' + systemID">
        <span class="link">System information</span>
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: "SubMenu",
  props: {
    countryCode: String,
    systemID: String
  }
};
</script>

<style scoped>
.submenu {
  margin: 0;
  padding: 0;
  background: rgba(255, 255, 255, 0.05);
  list-style: none;
  z-index: 10;
  border-bottom: 1px solid #dddddd;
}
.submenu li {
  display: inline-block;
}
.submenu a,
.submenu .active {
  display: block;
  padding: 0.5rem 1rem;
}
a.router-link-exact-active {
  color: #1d1d1f;
  background: rgba(0, 0, 0, 0.05);
  pointer-events: none;
  font-weight: 500;
  letter-spacing: 0.01rem;
}
a.router-link-exact-active .link {
  border: none;
}
@media (prefers-color-scheme: dark) {
  .submenu {
    background: #2a2a2a;
    border-color: #4d4d4d;
  }
  a.router-link-exact-active {
    color: #d8d8d8;
    background: #3a3a3a;
  }
}
</style>
